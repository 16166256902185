import React from 'react';
import {FOTO01} from "../../shared/images";

const Hero = () => {
    return (
        <div>
            <div className="flex flex-wrap">
                <div className="w-full sm:w-8/12 mb-10">
                    <div className="container mx-auto h-full sm:p-10">
                        <nav className="px-4 justify-between items-center">
                            <div className="text-xl">
                                Банкетный зал
                            </div>
                            <div className="text-4xl font-bold mb-2">
                                Городок<span className="text-green-700">ъ</span>
                            </div>
                            {/*<div>*/}
                            {/*    <img src="https://image.flaticon.com/icons/svg/497/497348.svg" alt="" className="w-8" />*/}
                            {/*</div>*/}
                        </nav>
                        <div className="px-4">
                            г. Улан-Удэ, ул. Толстого д.3
                            <div className="text-2xl mt-3">
                                +7(9025) <span className="text-3xl font-bold">653-123</span>
                            </div>
                        </div>
                        <header className="container px-4 lg:flex mt-4 items-center h-full lg:mt-0">
                            <div className="w-full">
                                <h1 className="text-4xl lg:text-6xl font-bold me-2">Свадьбы <span
                                    className="text-green-700">Банкеты</span> Юбилеи</h1>
                                <div className="w-20 h-2 bg-green-700 my-4" />
                                <p className="text-xl mb-10">Банкетный зал до 250 человек</p>
                                <button
                                    className="bg-green-500 text-white text-2xl font-medium px-4 py-2 rounded shadow">
                                    Работаем с 2004 года
                                </button>
                            </div>
                        </header>
                    </div>
                </div>
                <img src={FOTO01} className="w-full h-screen object-cover sm:h-screen sm:w-4/12" alt='Банкетный зал'/>
                {/*<img*/}
                {/*    src="https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"*/}
                {/*    alt="Leafs" className="w-full h-48 object-cover sm:h-screen sm:w-4/12" />*/}
            </div>
        </div>
    );
};

export default Hero;