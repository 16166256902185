import React from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";
import {FaCircleArrowLeft} from "react-icons/fa6";

export const NotFound = () => {
    const navigate = useNavigate()
    return (
        <Result
            status="error"
            title="404"
            subTitle="Sorry, the section you want to open is not available"
            extra={<Button type="primary" block={true} onClick={() => navigate(-1)}>
                <FaCircleArrowLeft />
            </Button>}
        />
    );
};