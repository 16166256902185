import React from 'react';
import Hero from "../../widgets/main/Hero";
import HeaderMain from "../../widgets/headers/HeaderMain";

const Main = () => {
    return (
        <div className="flex content-center justify-center">
            <div className="max-w-5xl">
                {/*<HeaderMain />*/}

                <Hero />
            </div>
        </div>
    );
};

export default Main;