import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
// import {TelegramInit} from "../../shared/api/telegram";
import {appRoutes} from "./routers";
import {NotFound} from "../../pages/main/NotFound";
import {ConfigProvider} from "antd";

const AppRouter = () => {
    return (
        <div>
            <BrowserRouter>
                <ConfigProvider
                    theme={{
                        token: {
                            fontSize: 16, // all fonts size
                            sizeUnit: 4, //Единица изменения размера в Ant Design наша базовая единица — 4, что обеспечивает более детальный контроль шага размера.
                            sizeStep: 6, // отсутпы в инпутах
                            controlHeight: 36, //Высота основных элементов управления, таких как кнопки и поля ввода.
                        },
                    }}
                    componentSize="large"
                >
                    {/*<TelegramInit />*/}
                    <Routes>
                        {appRoutes.map(({path, Component}) =>
                            <Route
                                key={path}
                                path={path}
                                element={<Component/>}
                                exact
                            />
                        )}
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                </ConfigProvider>
            </BrowserRouter>
        </div>
    );
};

export default AppRouter;